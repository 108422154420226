const moduleName = 'filter';

export function filter() {

	Array.from(document.querySelectorAll('.js-filter')).forEach((self) => {
		if (self.dataset[moduleName + 'Init'] === 'true') return;
		self.dataset[moduleName + 'Init'] = true;

		const filter = self;
		const toggle = filter.querySelector('.js-filter-toggle');
		
		toggle.addEventListener('click', (event) => {
			filter.classList.toggle('_open');
			event.preventDefault();
		});

		document.addEventListener('click', (event) => {
			if (!event.target.closest('.js-filter') && filter.classList.contains('_open')) filter.classList.remove('_open');
		});
	});
}

