const moduleName = 'nav';

export function nav() {

    Array.from(document.querySelectorAll('.js-nav')).forEach((self) => {
        if (self.dataset[moduleName + 'Init'] === 'true') return;
        self.dataset[moduleName + 'Init'] = true;

        const nav = self;
        const toggle = nav.querySelector('.js-nav-toggle');

        let isMenuOpen = () => {
            return nav.classList.contains('_open');
        };

        toggle.addEventListener('click', () => {
            if (isMenuOpen()) {
                hideMenu();
            } else {
                showMenu();
            }
        });

        document.addEventListener('click', (event) => {
            if (!event.target.closest('.js-nav') && isMenuOpen()) hideMenu();
        });



        function showMenu() {
            nav.classList.add('_open');
        }

        function hideMenu() {
            nav.classList.remove('_open');
        }
    });

}