const moduleName1 = 'popup';
const moduleName2 = 'popupOpenButton';

export function popup() {

    Array.from(document.querySelectorAll('.js-popup')).forEach((self) => {
        if (self.dataset[moduleName1 + 'Init'] === 'true') return;
        self.dataset[moduleName1 + 'Init'] = true;

        const popup = self;
        const popupCloseButtons = popup.querySelectorAll('.js-popup-close-button');

        if (popup.parentNode != document.body) {
            popup.parentNode.removeChild(popup);
            document.body.appendChild(popup);
        }

        Array.from(popupCloseButtons).forEach((popupCloseButton) => {
            popupCloseButton.addEventListener('click', closePopup);
        });

        popup.addEventListener('click', (event) => {
            if (!event.target.closest('.js-popup-body')) {
                closePopup();
            }
        });

        window.addEventListener('keydown', (event) => {
            if (event.keyCode === 27 && popup.classList.contains('_active')) {
                closePopup();
            }
        });
    });



    Array.from(document.querySelectorAll('.js-popup-open-button')).forEach((self) => {
        if (self.dataset[moduleName2 + 'Init'] === 'true') return;
        self.dataset[moduleName2 + 'Init'] = true;

        const button = self;
        const popupId = !!button.dataset.popup ? button.dataset.popup : button.getAttribute('href');

        button.addEventListener('click', (event) => {
            openPopup(`${popupId}`);
            event.preventDefault();
        });
    });



    function openPopup(popupId) {
        const popup = document.querySelector(popupId);
        const ytVideo = popup.querySelector('.js-popup-yt-video');

        closePopup();

        popup.classList.add('_active');

        setTimeout(() => {
            popup.classList.add('_visible');

            if (ytVideo) ytVideo.contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
        }, 0);

        overlay().show();
    }

    function closePopup() {
        const activePopup = document.querySelector('.js-popup._active');

        if (!activePopup) return;

        const ytVideo = activePopup.querySelector('.js-popup-yt-video');

        activePopup.classList.remove('_visible');

        if (ytVideo) ytVideo.contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');

        setTimeout(() => {
            activePopup.classList.remove('_active');

            if (activePopup.id == 'btu__result') {
                document.querySelector('[name=indent]').value = '';
                activePopup.querySelector('.result').innerHTML = '';
            }
        }, 200);

        overlay().hide();
    }


    return {
        open: openPopup,
        close: closePopup
    };

}