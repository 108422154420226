// polyfills
import 'core-js/fn/array/from';
import './modules/polyfills';

// detect mobile devices
import './modules/mobileDetect';

// jQuery
window.$ = window.jQuery = require('jquery');

// modules
import { popup } from './modules/popup';
import { overlay } from './modules/overlay';
import { promo } from './modules/promo';
import { nav } from './modules/nav';
import { tabs } from './modules/tabs';
import { filter } from './modules/filter';

window.overlay = overlay;
window.popup = popup;

window.initModules = () => {
    popup();
    promo();
    nav();
    tabs();
    filter();
    require('./modules/fancybox')();
};

if (document.readyState === 'complete' || document.readyState === 'loaded' || document.readyState === 'interactive') {
    initModules();
} else {
    document.addEventListener('DOMContentLoaded', initModules);
}