const moduleName = 'tabs';

export function tabs() {

	Array.from(document.querySelectorAll('.js-tabs')).forEach((self) => {
		if (self.dataset[moduleName + 'Init'] === 'true') return;
		self.dataset[moduleName + 'Init'] = true;

		const tabs = self;
		const links = tabs.querySelectorAll('.js-tabs-link');
		const contents = tabs.querySelectorAll('.js-tabs-content');
		let linksArray = [];
		let contentsArray = [];

		Array.from(links).forEach((link) => {
			if (link.closest('.js-tabs') != tabs) return;

			linksArray.push(link);
		});

		Array.from(contents).forEach((content) => {
			if (content.closest('.js-tabs') != tabs) return;

			contentsArray.push(content);
		});

		linksArray.forEach((link) => {
			link.addEventListener('click', (event) => {
				event.preventDefault();
				
				if (link.classList.contains('_active')) return;

				let id = link.getAttribute('href');

				activateTab(id);
			});
		});



		function activateTab(id) {
			linksArray.forEach((link) => {
				if (link.getAttribute('href') === id) {
					link.classList.add('_active');
				} else {
					link.classList.remove('_active');
				}
			});

			contentsArray.forEach((content) => {
				if (content.id === id.substring(1)) {
					content.classList.add('_active');
				} else {
					content.classList.remove('_active');
				}
			});
		}
	});
}

