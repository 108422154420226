import Swiper from 'swiper';

const moduleName = 'promo';

export function promo() {

	Array.from(document.querySelectorAll('.js-promo')).forEach((self) => {
		if (self.dataset[moduleName + 'Init'] === 'true') return;
		self.dataset[moduleName + 'Init'] = true;

		const slider = self;
		const pagination = slider.querySelector('.js-promo-pagination');

		const reviewsSwiper = new Swiper(slider, {
			simulateTouch: false,
			loop: true,
			autoplay: {
				delay: 5000
			},
			pagination: {
				el: pagination,
				clickable: true,
				bulletClass: 'promo__bullet',
				bulletActiveClass: '_active'
			}
		});
	});

}